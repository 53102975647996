:root { 
--gray_400:#c4c4c4; 
--amber_400:#fdc221; 
--gray_800:#404040; 
--light_blue_50:#d4efff; 
--black_900_0c:#0000000c; 
--black_900_3f:#0000003f; 
--blue_50:#ebf7ff; 
--red_100:#ffded4; 
--red_100_01:#ffd4d4; 
--orange_50:#ffedd4; 
--black_900_23:#00000023; 
--blue_gray_900_a8:#331b3ba8; 
--black_900:#000000; 
--yellow_700:#fdc021; 
--red_100_4c:#ffded44c; 
--blue_gray_900_01:#343434; 
--blue_400:#50a0db; 
--blue_gray_900:#331b3b; 
--black_900_19:#00000019; 
--black_900_14:#00000014; 
--white_A700:#ffffff; 
}