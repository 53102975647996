* {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

.code,
.navigation {
  min-height: 100%;
}

.common-pointer {
  cursor: pointer;
}

.navigation {
  padding: 20px;
  background-color: #232323;
}

.navigation h1 {
  color: white;
}

.navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}

.navigation ul {
  padding: 0;
}

.navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}

.navigation a {
  color: #132cdc;
}

table {
  border-spacing: 0;
  width: 100%;
}

.common-select {
  z-index: 10000;
}

.react-datepicker-popper {
  z-index: 100000 !important;
}

.drawable-icon {
  position: absolute;
  margin: auto;
  z-index: 1000;
}

.input-wrap {
  position: relative;
}

option {
  color: #000;
}

.table-wrap {
  overflow: auto;
}

input:focus {
  outline: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.slider-indicator:first-child {
  margin-left: 0;
}

.slider-indicator:last-child {
  margin-right: 0;
}

.rc-drawer-content-wrapper {
  width: 100% !important;
}

.rc-drawer-right .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-end;
}

.rc-drawer-left .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-start;
}

.ReactModal__Overlay {
  z-index: 1051;
}

ul li a {
  display: block;
}

.mobile-menu div {
  height: 1.5px;
  width: 20px;
  background-color: #000;
  margin: 4px 0;
}

.mobile-menu {
  display: none;
  cursor: pointer;
}

.header-row {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .mobile-menu {
    display: block;
  }

  .header-row {
    width: 100% !important;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .common-row-list>ul {
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
}

.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}

.menu-overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  left: 0px;
  top: 0px;
}

.close-line1 {
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.close-line2 {
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.common-row-list>ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list>ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.common-column-list li {
  width: 100%;
}

/* scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}

.sm__displaynone_mobile {
  display: block !important;
}

.sm__displayblock_mobile {
  display: none !important;
}

.sm__main_left_mobile__button {
  padding: 15px !important;
  width: 100%;
}

.web__img___navbar_adjustment {
  /* margin-right: -70px;
  margin-top: -65px; */
  position: absolute;
  right: 0px;
  width: 50%;
  top: 0;
  width: calc(70% - 20%) !important;
}

/* Media Query CSS */
@media (max-width: 450px) {

  /* .w_mobile__display_none {
    display: none;
  } */
  .slick-list {
    height: 100% !important;
  }

  .whatwedo__svg_icon {
    width: 400px !important;
    position: absolute !important;
    right: 0px !important;
    /* right: -28px !important; */
    top: -70px !important;
    z-index: -1 !important;
  }

  .sm__flex_col_mobile {
    flex-direction: column !important;
  }

  .sm__main_right_mobile__img {
    /* margin-top: 390px; */
    width: 100% !important;
    margin-right: -12px !important;
    margin-top: 0px !important;
  }

  .sm__main_section_mt_0 {
    margin-top: 80px !important;
  }

  .sm__mobile_w_100__percent {
    width: 100%;
  }

  .sm_testimonials_bar_mobile {
    bottom: 27px !important;
    right: 37% !important;
    width: 26% !important;
  }

  .sm__displaynone_mobile {
    display: none !important;
  }

  .sm__displayblock_mobile {
    display: block !important;
  }
}

@media (max-width: 780px) {
  .m_none_and_p_none {
    margin: 0 !important;
    padding: 0 !important;
  }

  .w_mobile__display_none {
    display: none;
  }
}

@media (max-width: 1100px) {
  .our_experts__ms__190 {
    margin-left: 0px !important;
  }

  .web__img___navbar_adjustment {
    position: unset;
    width: 100% !important;
  }
}

@media only screen and (min-width: 1550px) and (max-width: 1849px) {
  .web__img___navbar_adjustment {
    width: 45% !important;
  }
}

@media only screen and (min-width: 1850px) and (max-width: 2000px) {
  .web__img___navbar_adjustment {
    width: 40% !important;
  }
}

@media (min-width: 2001px) {
  .web__img___navbar_adjustment {
    width: 30% !important;
  }
}

.our_experts__ms__190 {
  margin-left: -150px;
}

.md_w_95__percent {
  width: 95% !important;
}

/* Slicker CSS */
.whattodo__carousel .slick-arrow {
  color: #070606 !important;
  border: none;
  outline: none;
  background: #242424 !important;
}

/* .slick-slide {
  margin-right: 15px;
} */

.slick-prev {
  left: 3% !important;
  z-index: 1;
}

.slick-next {
  right: 3% !important;
  z-index: 1;
}

.slick-list {
  padding: 0 20% 0 0 !important;
}

.ourexperts__arrows_section {
  position: absolute;
  bottom: 30px;
  left: 25px;
}

/* Theme CSS */
.bg-black-900 {
  background-color: #2B2B2B !important;
}

.bg-amber-400 {
  background-color: #7D40F6 !important;
}

.bg-red-100_4c,
.bg-blue-50 {
  background-color: #d8c5fc !important;
}

.whatwedo__svg_icon {
  width: 500px;
  position: absolute;
  right: 0px;
  top: -170px;
  z-index: -1;
}

.invalid-email {
  border: 1px solid red !important;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #7D40F6;
  transform-origin: 0%;
  z-index: 1000;
}